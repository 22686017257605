import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export type ThemeType = 'dark' | 'light';

const CURRENT_THEME = '_current-theme';
const DEFAULT_THEME = 'dark';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  $currentTheme: BehaviorSubject<ThemeType> = new BehaviorSubject<ThemeType>(DEFAULT_THEME);

  init() {
    let theme: ThemeType = <ThemeType>localStorage.getItem(CURRENT_THEME);
    theme = theme ? theme : DEFAULT_THEME
    localStorage.setItem(CURRENT_THEME, theme);
    this.$currentTheme.next(theme);
    this.changeCurrentTheme(theme);
  }

  changeCurrentTheme(theme: ThemeType) {
    document.body.setAttribute('color-theme', theme);
    localStorage.setItem(CURRENT_THEME, theme);
    this.$currentTheme.next(theme);
  }
}
